@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 403px) {
  .loading-spinner {
    margin: 0;
    /* position: fixed;
      z-index: 1031;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); */
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    height: calc(100vh - 300px);
  }
}

@media screen and (min-width: 404px) and (max-width: 530px) {
  .loading-spinner {
    margin: 0;
    /* position: fixed;
    z-index: 1031;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    height: calc(100vh - 250px);
  }
}

@media screen and (min-width: 531px) {
  .loading-spinner {
    margin: 0;
    /* position: fixed;
    z-index: 1031;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    height: calc(100vh - 228px);
  }
}