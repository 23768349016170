.userProposalTableContainer,
.requestReportTableContainer,
.premiumAppUsertableContainer,
.incometableContainer,
.heighttableContainer,
.diettableContainer,
.educationtableContainer,
.scommunitytableContainer,
.communitytableContainer,
.religiontableContainer,
.timedurationtableContainer,
.premiumFacilitytableContainer,
.UsertableContainer,
.blockUsertableContainer,
.apptableContainer,
.premiumAccounttableContainer {
    overflow-x: hidden;
}

.region-div{
    width: 100%;

}
.country-tableContainer-list {
    height: calc(100vh - 290px);
    /* overflow-x: hidden; */
    /* width: 100%; */
}

@media (max-width: 960px) {

    .userProposalTableContainer,
    .requestReportTableContainer,
    .premiumAppUsertableContainer,
    .incometableContainer,
    .heighttableContainer,
    .diettableContainer,
    .educationtableContainer,
    .scommunitytableContainer,
    .communitytableContainer,
    .religiontableContainer,
    .timedurationtableContainer,
    .premiumFacilitytableContainer,
    .UsertableContainer,
    .blockUsertableContainer,
    .apptableContainer,
    .premiumAccounttableContainer {
        overflow-x: auto;
    }
}

@media screen and (min-width: 0px) and (max-width: 769px) {
    .button {
        display: block;
    }

    .buttonLarge {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .button {
        display: none;
    }

    .buttonLarge {
        display: block;
    }
}

/* appuser && faqs */
@media screen and (min-width: 450px) {
    .appcard {
        height: calc(100vh - 228px);
    }

    .apptableContainer {
        height: calc(100vh - 305px);
    }
}

@media screen and (max-width: 449px) {
    .appcard {
        height: calc(100vh - 258px);
    }

    .apptableContainer {
        height: calc(100vh - 337px);
    }
}

@media screen and (min-width: 450px) {
    .premiumAccounttableContainer {
        height: calc(100vh - 275px);
    }
}

@media screen and (max-width: 449px) {
    .premiumAccounttableContainer {
        height: calc(100vh - 275px);
    }
}

/* @media screen and (min-width: 404) and (max-width: 449px) {
    .card {
        height: calc(100vh - 250px);
    }
    .tableContainer {
        height: calc(100vh - 327px);
    }
} */

/* @media screen and (max-width: 403px) {
    .card {
        height: calc(100vh - 300px);
    }
    .tableContainer {
        height: calc(100vh - 377px);
    }
} */


/* blockUser */
@media screen and (min-width: 426px) {
    .blockUsercard {
        height: calc(100vh - 228px);
    }

    .blockUsertableContainer {
        height: calc(100vh - 305px);
    }
}

@media screen and (max-width: 425px) {
    .blockUsercard {
        height: calc(100vh - 315px);
    }

    .blockUsertableContainer {
        height: calc(100vh - 392px);
    }
}

/* successStories */
@media screen and (max-width: 320px) {
    .Successcard {
        height: calc(100vh - 259px);
    }

    .SuccessContainer {
        height: calc(100vh - 336px);
    }
}

@media screen and (min-width: 321px) {
    .Successcard {
        height: calc(100vh - 228px);
    }

    .SuccessContainer {
        height: calc(100vh - 305px);
    }
}

/* adminUser */

@media screen and (min-width: 491px) {
    .Usercard {
        height: calc(100vh - 228px);
    }

    .UsertableContainer {
        height: calc(100vh - 305px);
    }
}

@media screen and (min-width: 404px) and (max-width: 490px) {
    .Usercard {
        height: calc(100vh - 250px);
    }

    .UsertableContainer {
        height: calc(100vh - 327px);
    }
}

@media screen and (max-width: 403px) {
    .Usercard {
        height: calc(100vh - 300px);
    }

    .UsertableContainer {
        height: calc(100vh - 377px);
    }
}

/* feedback */

/* @media screen and (max-width: 320px) {
    .feedbackcard {
        height: calc(100vh - 228px);
    }
    .feedbacktableContainer {
        height: calc(100vh - 305px);
    }
} */

/* religion & height*/
@media screen and (min-width: 505px) {
    .religioncard {
        height: calc(100vh - 228px);
    }

    .religiontableContainer {
        height: calc(100vh - 305px);
    }
}

@media screen and (min-width: 404px) and (max-width: 504px) {
    .religioncard {
        height: calc(100vh - 250px);
    }

    .religiontableContainer {
        height: calc(100vh - 327px);
    }
}

@media screen and (max-width: 403px) {
    .religioncard {
        height: calc(100vh - 300px);
    }

    .religiontableContainer {
        height: calc(100vh - 377px);
    }
}

/* community & marital Status & employment & occupation*/
@media screen and (max-width: 403px) {
    .communitycard {
        height: calc(100vh - 300px);
    }

    .communitytableContainer {
        height: calc(100vh - 377px);
    }
}

@media screen and (min-width: 404px) and (max-width: 530px) {
    .communitycard {
        height: calc(100vh - 250px);
    }

    .communitytableContainer {
        height: calc(100vh - 327px);
    }
}

@media screen and (min-width: 531px) {
    .communitycard {
        height: calc(100vh - 228px);
    }

    .communitytableContainer {
        height: calc(100vh - 305px);
    }
}

/* subCommunity */
@media screen and (max-width: 403px) {
    .scommunitycard {
        height: calc(100vh - 300px);
    }

    .scommunitytableContainer {
        height: calc(100vh - 377px);
    }
}

@media screen and (min-width: 404px) and (max-width: 561px) {
    .scommunitycard {
        height: calc(100vh - 250px);
    }

    .scommunitytableContainer {
        height: calc(100vh - 327px);
    }
}

@media screen and (min-width: 562px) {
    .scommunitycard {
        height: calc(100vh - 228px);
    }

    .scommunitytableContainer {
        height: calc(100vh - 305px);
    }
}

/* education */

@media screen and (max-width: 403px) {
    .educationcard {
        height: calc(100vh - 300px);
    }

    .educationtableContainer {
        height: calc(100vh - 377px);
    }
}

@media screen and (min-width: 404px) and (max-width: 519px) {
    .educationcard {
        height: calc(100vh - 250px);
    }

    .educationtableContainer {
        height: calc(100vh - 327px);
    }
}

@media screen and (min-width: 520px) {
    .educationcard {
        height: calc(100vh - 228px);
    }

    .educationtableContainer {
        height: calc(100vh - 305px);
    }
}

/* diet */
@media screen and (max-width: 403px) {
    .dietcard {
        height: calc(100vh - 300px);
    }

    .diettableContainer {
        height: calc(100vh - 377px);
    }
}

@media screen and (min-width: 404px) and (max-width: 477px) {
    .dietcard {
        height: calc(100vh - 250px);
    }

    .diettableContainer {
        height: calc(100vh - 327px);
    }
}

@media screen and (min-width: 478px) {
    .dietcard {
        height: calc(100vh - 228px);
    }

    .diettableContainer {
        height: calc(100vh - 305px);
    }
}

/* height */
@media screen and (max-width: 412px) {
    .heightcard {
        height: calc(100vh - 300px);
    }

    .heighttableContainer {
        height: calc(100vh - 377px);
    }
}

@media screen and (min-width: 413px) and (max-width: 505px) {
    .heightcard {
        height: calc(100vh - 250px);
    }

    .heighttableContainer {
        height: calc(100vh - 327px);
    }
}

@media screen and (min-width: 505px) {
    .heightcard {
        height: calc(100vh - 228px);
    }

    .heighttableContainer {
        height: calc(100vh - 305px);
    }
}

/* income */
@media screen and (max-width: 403px) {
    .incomecard {
        height: calc(100vh - 300px);
    }

    .incometableContainer {
        height: calc(100vh - 377px);
    }
}

@media screen and (min-width: 404px) and (max-width: 553px) {
    .incomecard {
        height: calc(100vh - 250px);
    }

    .incometableContainer {
        height: calc(100vh - 327px);
    }
}

@media screen and (min-width: 554px) {
    .incomecard {
        height: calc(100vh - 228px);
    }

    .incometableContainer {
        height: calc(100vh - 305px);
    }
}

/* proposalRequestSend proposalRequestAccept proposalRequestReject */
@media screen and (min-width: 496px) {
    .requestReportcard {
        height: calc(100vh - 228px);
    }

    .requestReportTableContainer {
        height: calc(100vh - 228px);
    }
}

@media screen and (max-width: 495px) {
    .requestReportcard {
        height: calc(100vh - 260px);
    }

    .requestReportTableContainer {
        height: calc(100vh - 260px);
    }
}

/* proposalReceiveUser && proposalrejectUser && proposalSendUser && topRequestSend && topRequestReceive  && AppilicationUser && systemBlock*/
@media screen and (min-width: 375px) {
    .userProposalcard {
        height: calc(100vh - 228px);
    }

    .userProposalTableContainer {
        height: calc(100vh - 305px);
    }
}

@media screen and (max-width: 374px) {
    .userProposalcard {
        height: calc(100vh - 260px);
    }

    .userProposalTableContainer {
        height: calc(100vh - 337px);
    }
}

/* premiumAppUser */

@media screen and (max-width: 385px) {
    .premiumAppUsercard {
        height: calc(100vh - 305px);
    }

    .premiumAppUsertableContainer {
        height: calc(100vh - 382px);
    }
}

@media screen and (min-width: 386px) and (max-width: 572px) {
    .premiumAppUsercard {
        height: calc(100vh - 266px);
    }

    .premiumAppUsertableContainer {
        height: calc(100vh - 343px);
    }
}

@media screen and (min-width: 573px) {
    .premiumAppUsercard {
        height: calc(100vh - 228px);
    }

    .premiumAppUsertableContainer {
        height: calc(100vh - 305px);
    }
}